import services from "./index";
import store from "@/store";
import { getAuth } from "firebase/auth";
import qs from "querystring";

export default {
    impersonationProfileId: null,
    getConfig() {
        return {
            headers: {
                //authorization: `Bearer ${store.getters.jwtToken}`,
                "x-impersonation-profile-id": this.impersonationProfileId || '',
                //"x-tenant-id": process.env.VUE_APP_TENANT,
            }
        };
    },
    findEvents(query) {
        return services.http.get(`/v1.0/admin/events?query=${encodeURIComponent(query||'')}`, this.getConfig());
    },
    applyOrgLicense(id, license, quantity, invoiced) {
        return services.http.post(`/v1.0/admin/orgs/${id}/apply-license?license=${license||''}&invoicedAmount=${quantity||'0'}&invoiced=${invoiced||''}`, this.getConfig());
    },
    applyEventLicense(id, license, quantity, supportPlan, invoiced) {
        return services.http.post(`/v1.0/admin/events/${id}/apply-license?license=${license||''}&invoicedAmount=${quantity||'0'}&supportPlan=${supportPlan||''}&invoiced=${invoiced||''}`, this.getConfig());
    },
    applyExtendedDuration(id, duration) {
        return services.http.post(`/v1.0/admin/events/${id}/apply-extended-duration/${duration||''}`, this.getConfig());
    },
    reprocessEvent(id) {
        return services.http.post(`/v1.0/admin/events/${id}/reprocess?async=true&skip&take=4000`, this.getConfig());
    },
    unlockFullCustomization(id) {
        return services.http.post(`/v1.0/admin/events/${id}/unlock-full-customization`, this.getConfig());
    },
    getProfile(id) {
        return services.http.get(`/v1.0/admin/profile/${id}`, this.getConfig());
    },
    deleteToken(provider, providerId) {
        return services.http.delete(`/v1.0/admin/tokens/${provider}/${providerId}`, this.getConfig());
    },
    debugProvider(provider, profileId) {
        return services.http.get(`/v1.0/${provider}/debug?profileId=${profileId}&daysInPast=14`, this.getConfig());
    },
    updateProfile(id, email) {
        const request = { email:email };

        return services.http.put(`/v1.0/admin/profile/${id}`, qs.encode(request), this.getConfig());
    },
    getTrainingPlan(id) {
        return services.http.get(`/v1.0/admin/training-plans/${id}`, this.getConfig());
    },

    exportTenantReport(id, report) {
        return services.http.get(`/v1.0/admin/tenants/${id}/reports/${report}`, { ...this.getConfig(), responseType: 'blob' });
    },

    async getCookie() {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            var token = await user.getIdToken();
            var request = { id_token: token };
            return services.http.post(`/v1.0/oauth/create-cookie`, request, this.getConfig());
        }
    },
    
}